import React from "react";
import { Drawer, Typography, Box } from "@mui/material";
import { useAuth } from "../AuthProvider";
import { useTokenTracker } from "../TokenTrackerContext";
import { useTask } from "../TaskContext"; // Import useTask hook

const DrawerComponent = ({ drawerOpen, setDrawerOpen, tokenInfo }) => {
  const { userName } = useAuth();
  const { tokenData } = useTokenTracker();
  const { usageTask, taskUsage } = useTask();

  // Mapping of endpoint names to display names
  const imageModelNames = {
    "fal-ai/stable-diffusion-v3-medium": "Stable Diffusion v3 Medium",
    "fal-ai/fast-sdxl": "Fast SDXL",
    "fal-ai/playground-v25": "Playground v2.5",
    "fal-ai/flux-pro": "FLUX.1 [pro]",
    "fal-ai/flux/schnell": "FLUX.1 [schnell]",
    "fal-ai/flux/dev": "FLUX.1 [dev]",
    "fal-ai/flux-realism": "FLUX Realism LoRA",
  };

  const extractLLMInfo = (tokenInfo) => {
    if (!tokenInfo) return null;

    const lines = tokenInfo.split("\n");
    let info = {
      model: "",
      promptTokens: 0,
      completionTokens: 0,
      totalTokens: 0,
      cost: 0,
      sessionTotalTokens: 0,
      sessionTotalCost: 0,
      modelsUsed: "",
    };

    for (const line of lines) {
      if (line.startsWith("Model:")) info.model = line.split(":")[1].trim();
      if (line.startsWith("Prompt tokens:"))
        info.promptTokens = parseInt(line.split(":")[1].trim(), 10);
      if (line.startsWith("Completion tokens:"))
        info.completionTokens = parseInt(line.split(":")[1].trim(), 10);
      if (line.startsWith("Total tokens:"))
        info.totalTokens = parseInt(line.split(":")[1].trim(), 10);
      if (line.startsWith("Cost (PHP):"))
        info.cost = parseFloat(line.split(":")[1].trim());
      if (line.startsWith("Session Total Tokens:"))
        info.sessionTotalTokens = parseInt(line.split(":")[1].trim(), 10);
      if (line.startsWith("Session Total Cost (PHP):"))
        info.sessionTotalCost = parseFloat(line.split(":")[1].trim());
      if (line.startsWith("Models Used:"))
        info.modelsUsed = line.split(":")[1].trim();
    }

    return info;
  };

  const llmInfo = extractLLMInfo(tokenInfo);

  const formatTaskUsage = (taskUsageObj) => {
    if (!taskUsageObj || Object.keys(taskUsageObj).length === 0) {
      return "None";
    }
    return Object.entries(taskUsageObj)
      .map(([task, count]) => `${task} (${count}x)`)
      .join(", ");
  };

  const hasLLMActivity = llmInfo && llmInfo.totalTokens > 0;
  const hasImageActivity = tokenData.totalImageCostPHP > 0;
  const hasAudioActivity = tokenData.totalAudioCostPHP > 0;
  const audioModelUsed = tokenData.modelsUsed["Whisper v3"] || 0;
  const hasAnyActivity = hasLLMActivity || hasImageActivity || hasAudioActivity;

  const formatOperations = (operations) => {
    return Object.entries(operations)
      .map(([operation, count]) => `${operation} (${count}x)`)
      .join(", ");
  };

  const formatImageModelsUsed = (modelsUsed, imageOperations) => {
    const createImageCount = imageOperations["Create Image"] || 0;
    const transformImageCount = imageOperations["Transform Image"] || 0;

    let result = [];

    if (createImageCount > 0) {
      Object.entries(modelsUsed)
        .filter(([model]) => model.startsWith("fal-ai/"))
        .forEach(([model, count]) => {
          const displayName = imageModelNames[model] || model;
          result.push(`${displayName} (${count} images)`);
        });
    }

    if (transformImageCount > 0) {
      result.push(`SD 1.5 Depth ControlNet (${transformImageCount} images)`);
    }

    return result.join(", ");
  };

  const costInfo = hasAnyActivity
    ? `
LLM Usage:
${
  hasLLMActivity
    ? `Task: ${usageTask || "Unknown"}
Model: ${llmInfo.model || "Unknown"}
Prompt tokens: ${llmInfo.promptTokens || 0}
Completion tokens: ${llmInfo.completionTokens || 0}
Total Tokens: ${llmInfo.totalTokens || 0}
Cost (PHP): ${(llmInfo.cost || 0).toFixed(6)}
Session Total Tokens: ${llmInfo.sessionTotalTokens || 0}
Total LLM Cost (PHP): ${(llmInfo.sessionTotalCost || 0).toFixed(2)}
Tasks: ${formatTaskUsage(taskUsage)}
Models Used: ${llmInfo.modelsUsed || "Unknown"}`
    : "No LLM usage yet."
}

Image Operations:
${
  hasImageActivity
    ? `Latest Operation: ${
        tokenData.lastImageOperation
      } Inference Time: ${tokenData.lastImageInferenceTime.toFixed(
        3
      )}s Cost per Image (PHP): ${tokenData.latestImageCostPHP.toFixed(2)} ${
        tokenData.lastImageOperation === "Create Image"
          ? `Number of Images: ${tokenData.lastImageNumImages}`
          : ""
      } Total Cost (PHP): ${(
        tokenData.latestImageCostPHP *
        (tokenData.lastImageOperation === "Create Image"
          ? tokenData.lastImageNumImages
          : 1)
      ).toFixed(
        2
      )} Overall Image Cost (PHP): ${tokenData.totalImageCostPHP.toFixed(
        2
      )} Operations: ${formatOperations(
        tokenData.imageOperations
      )} Models Used: ${formatImageModelsUsed(
        tokenData.modelsUsed,
        tokenData.imageOperations
      )}`
    : "No image operations yet."
}

Audio Operations:
${
  hasAudioActivity
    ? `Latest Operation: ${tokenData.lastAudioOperation}
Latest Audio Cost (PHP): ${tokenData.latestAudioCostPHP.toFixed(2)}
Total Audio Cost (PHP): ${tokenData.totalAudioCostPHP.toFixed(2)}
Operations: ${formatOperations(tokenData.audioOperations)}
Model Used: Whisper v3 (${audioModelUsed}x)`
    : "No audio operations yet."
}

Session Overall Usage:
Total Cost (PHP): ${(
        (llmInfo?.sessionTotalCost || 0) +
        tokenData.totalImageCostPHP +
        tokenData.totalAudioCostPHP
      ).toFixed(2)}
`.trim()
    : "No usage data yet.";

  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      ModalProps={{ keepMounted: true }}
      sx={{
        width: 280,
        "& .MuiDrawer-paper": {
          width: "280px",
          backgroundColor: "#282c34",
          color: "#f0f0f0",
          padding: "10px",
        },
      }}
    >
      <Box
        sx={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          height: "92%",
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: "#f0f0f0", marginBottom: "20px" }}
        >
          Hi, {userName}!
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{
            color: "#f0f0f0",
            fontSize: "14px",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Changelog
        </Typography>
        <div
          style={{
            backgroundColor: "#282c34",
            color: "#f0f0f0",
            padding: "10px",
            height: "50vh",
            overflowY: "auto",
            fontSize: "12px",
            border: "1px solid #444",
            borderRadius: "4px",
          }}
          className="drawer-whatsnew-textarea"
        >
          <p style={{ margin: 0 }}>09-19-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Refactored for more robust user and organization management logic
            </li>
          </ul>
          <p style={{ margin: 0 }}>09-18-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Added chatgpt-4o-latest model in Write tab as "GPT-4o (latest)";
              "Dynamic model continuously updated to the current version of
              GPT-4o in ChatGPT."
            </li>
          </ul>
          <p style={{ margin: 0 }}>08-23-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>Added FLUX Realism LoRA model in Create Image tab</li>
          </ul>
          <p style={{ margin: 0 }}>08-19-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Revamped Summarize PDF logic (leveraging long context window and
              new prompt caching feature in Claude API; chunking based on
              document outline) Note: barebones implementation without token
              tracking.
            </li>
          </ul>
          <p style={{ margin: 0 }}>08-08-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Updated to GPT-4o to gpt-4o-2024-08-06 (support for structured
              outputs; 16k max output tokens; lower cost).
            </li>
            <li>Made login error messages more secure.</li>
          </ul>
          <p style={{ margin: 0 }}>08-01-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Added FLUX.1 (pro, dev, and schnell) models to Create Image tab.
              FLUX.1 [schnell] as new default for text-to-image function.
            </li>
          </ul>
          <p style={{ margin: 0 }}>07-24-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>Added Groq Llama-3.1-70b-Verstaile model to Write tab</li>
          </ul>
          <p style={{ margin: 0 }}>07-19-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Added OpenAI GPT-4o mini model in Write tab; used in Summarize PDF
              and Chat with PDF tabs.
            </li>
            <li>Revised Usage Information</li>
          </ul>
          <p style={{ margin: 0 }}>07-17-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Added negative prompt to create and transform image tasks in
              Create Image tab
            </li>
            <li>
              Implemented more secure password requirement for new users in
              Registration page
            </li>
          </ul>
          <p style={{ margin: 0 }}>07-12-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Added number of images selection for image creation task in Create
              Images tab.
            </li>
          </ul>
          <p style={{ margin: 0 }}>07-05-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Added 'PPTX' button to convert and save text to Powerpoint file in
              Scratch Pad in Write and Summarize Articles tabs
            </li>
            <li>
              Replaced wand icon with button in Write and Summarize Articles
              tabs.
            </li>
          </ul>
          <p style={{ margin: 0 }}>07-04-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Implemented cost tracking for audio transcription and integrated
              it with cost tracking for text and image generation.
            </li>
          </ul>
          <p style={{ margin: 0 }}>07-03-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Implemented cost tracking for image generation and integrated it
              with cost tracking for text generation.
            </li>
          </ul>
          <p style={{ margin: 0 }}>06-29-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Added Transcribe Audio tab for transcription of audio files with
              Whisper v3.
            </li>
          </ul>
          <p style={{ margin: 0 }}>06-28-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Added image transformation task with Stable Diffusion 1.5 Depth
              ControlNet in Create Image tab.
            </li>
          </ul>
          <p style={{ margin: 0 }}>06-27-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>Added Enhance Prompt switch in Create Image tab.</li>
          </ul>
          <p style={{ margin: 0 }}>06-26-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Initial implementation of Create Image tab. Uses Stable Diffusion
              models to generate images from text.
            </li>
          </ul>
          <p style={{ margin: 0 }}>06-21-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Upgraded to Anthropic Claude 3.5 Sonnet model in Write and
              Summarize Articles tabs
            </li>
          </ul>
          <p style={{ margin: 0 }}>06-16-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>Added new error handling in Write tab</li>
          </ul>
          <p style={{ margin: 0 }}>06-03-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>Integrated Cloudflare CDN and security features</li>
          </ul>
          <p style={{ margin: 0 }}>05-28-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>Added Anthropic Claude 3 Opus model in Write tab</li>
            <li>Adjusted max_tokens in Write tab</li>
            <li>Renamed Take Notes tab as Chat with PDF</li>
            <li>Other UI tweaks</li>
          </ul>
          <p style={{ margin: 0 }}>05-27-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>Changed LLM defaults in Write tab</li>
          </ul>
          <p style={{ margin: 0 }}>05-25-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Swapped out ChromaDB for FAISS as vector store in Take Notes tab
            </li>
            <li>Improved responsive layout of Login and Registration pages</li>
          </ul>
          <p style={{ margin: 0 }}>05-16-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Added OpenAI GPT-4o model in Write and Summarize Articles tabs
            </li>
          </ul>
          <p style={{ margin: 0 }}>04-30-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              New feature: in Write tab⇒Press Release, Press Statement, and
              Makabayan Press Statement tasks, just paste URL link to news
              article in Your Prompt box then click yellow wand button to
              generate draft. The text content in the link will be fetched and
              used as context for your draft. You can type additional
              instructions after the URL link to give specific guidance on how
              the draft will be written.
            </li>
            {/*<li>Commented out User Role editing option in Admin Dashboard</li>*/}
          </ul>
          <p style={{ margin: 0 }}>04-24-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Added Anthropic Claude 3 Sonnet and Groq Llama-3 70B 8K models in
              Write tab
            </li>
            {/*<li>Commented out User Role editing option in Admin Dashboard</li>*/}
          </ul>
          <p style={{ margin: 0 }}>04-02-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>Material UI icons in app bar</li>
            <li>Improved placeholder texts in Write tab</li>
            {/*<li>Commented out User Role editing option in Admin Dashboard</li>*/}
          </ul>
          <p style={{ margin: 0 }}>04-01-2024</p>
          <ul style={{ lineHeight: "1.5", paddingLeft: "20px" }}>
            <li>
              Added Anthropic Claude 3 Haiku model (selectable in Write tab and
              used in Summarize Articles tab)
            </li>
            <li>
              Added Summary Styles dropdown for Summary/Outline task in Write
              tab
            </li>
            <li>Session-wide token usage tracking</li>
            <li>What's New section in side drawer</li>
          </ul>
        </div>

        {/* Token Count & Cost Section */}
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            marginTop: "10px",
          }}
        >
          Usage Information
        </Typography>
        <textarea
          value={costInfo}
          readOnly
          className="drawer-textarea"
          style={{
            backgroundColor: "#282c34",
            color: "#f0f0f0",
            padding: "10px",
            height: "30vh",
            overflowY: "auto",
            fontSize: "12px",
            border: "1px solid #444",
            borderRadius: "4px",
            resize: "none",
          }}
        />
      </Box>
    </Drawer>
  );
};

export default DrawerComponent;
